import { useEffect } from 'react';
import { setPageTitle } from './util';

/**
 * 设置页面标题，兼容小程序
 * @param title
 */
export function usePageTitle(title?: string) {
  useEffect(() => {
    setPageTitle(title);
  }, [title]);
}
