import classNames from 'classnames';
import { ISvgProps } from '@/types';

import './index.scss';

export const SvgRecord = (props: ISvgProps) => {
  const {
    width = '1em',
    height = '1em',
    className,
    style,
    onClick,
    fill = 'currentColor',
  } = props;
  return (
    <svg
      width={width}
      height={height}
      className={classNames('comp-icon', className)}
      style={style}
      onClick={onClick}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.00001 7.99997C5.00001 7.72383 5.22387 7.49997 5.50001 7.49997H12.5C12.7761 7.49997 13 7.72383 13 7.99997C13 8.27611 12.7761 8.49997 12.5 8.49997H5.50001C5.22387 8.49997 5.00001 8.27611 5.00001 7.99997Z"
        fill={fill}
      />
      <path
        d="M5.00001 10C5.00001 9.72389 5.22387 9.50003 5.50001 9.50003H11C11.2761 9.50003 11.5 9.72389 11.5 10C11.5 10.2762 11.2761 10.5 11 10.5H5.50001C5.22387 10.5 5.00001 10.2762 5.00001 10Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.75 1.75C3.09315 1.75 1.75 3.09315 1.75 4.75V13.25C1.75 14.9069 3.09315 16.25 4.75 16.25H13.25C14.9069 16.25 16.25 14.9069 16.25 13.25V4.75C16.25 3.09315 14.9069 1.75 13.25 1.75H4.75ZM2.75 4.75C2.75 3.64543 3.64543 2.75 4.75 2.75H5V3.75C5 4.57843 5.67157 5.25 6.5 5.25H11.75C12.5784 5.25 13.25 4.57843 13.25 3.75V2.75C14.3546 2.75 15.25 3.64543 15.25 4.75V13.25C15.25 14.3546 14.3546 15.25 13.25 15.25H4.75C3.64543 15.25 2.75 14.3546 2.75 13.25V4.75ZM6 3.75V2.75H12.25V3.75C12.25 4.02614 12.0261 4.25 11.75 4.25H6.5C6.22386 4.25 6 4.02614 6 3.75Z"
        fill={fill}
      />
    </svg>
  );
};
