import { IUser } from './types';
import { storage } from '@/utils';

const lsUserKey = '_uk';
export const cookieSessionKey = '_csk';

// 全局缓存，注意：使用中对用户字段的更新在刷新页面后无效，除非刷新前调用过 updateAndStoreLoginUser
let loginUser: IUser | undefined;

export const context = {
  /**
   * 更新登录用户信息
   * @param user
   */
  updateAndStoreUser(user?: IUser) {
    loginUser = user ?? loginUser;
    storage.setSession(lsUserKey, loginUser);
    storage.ck.set(cookieSessionKey, loginUser ? loginUser.token : '');
  },

  /**
   * 返回全局登录用户信息。注意：使用中对用户字段的更新在刷新页面后无效，除非刷新前调用过 updateAndStoreLoginUser
   * @returns
   */
  getUser(): IUser | undefined {
    if (storage.ck.get(cookieSessionKey)) {
      // 判断在有效期内
      if (!loginUser) {
        // 缓存中还没，从 localStorage 取
        loginUser = storage.getSession(lsUserKey) as IUser;
      }
    } else {
      // 失效
      loginUser = undefined;
    }
    return loginUser;
  },
};
