import qs from 'qs';

interface IObjectAny {
  [k: string | number]: any;
}

export class UrlTool {
  // url 查询参数 parsing and stringifying
  // 更多参考：https://www.npmjs.com/package/qs
  qs: typeof qs = qs;

  stringify(queryObj: IObjectAny, options?: qs.IStringifyOptions): string {
    if (!queryObj) {
      return '';
    }
    // qs by default URI encodes output.
    return qs.stringify(queryObj, options);
  }

  parse(
    queryStr: string,
    options?: qs.IParseOptions & { decoder?: never | undefined },
  ): qs.ParsedQs {
    if (!queryStr) {
      return {};
    }
    return qs.parse(queryStr, options) || {};
  }

  addUrlParams(url: string, queryObj: IObjectAny): string {
    const queryObjOld = this.getUrlParams(url);
    const queryStr = this.stringify(
      { ...queryObjOld, ...queryObj },
      {
        addQueryPrefix: true,
      },
    );
    const newUrl = url.split('?')[0];
    return newUrl + queryStr;
  }

  getUrlParams(url: string = window.location.href): IObjectAny {
    const queryStr = url.split('?')[1];
    return this.parse(queryStr);
  }

  /**
   * 获取公众号授权地址
   * 更多参考 https://developers.weixin.qq.com/doc/offiaccount/OA_Web_Apps/Wechat_webpage_authorization.html#0
   * @param appid 公众号的唯一标识
   * @param redirectUri 授权后重定向的回调链接地址
   * @param scope 应用授权作用域，snsapi_base （不弹出授权页面，直接跳转，只能获取用户openid），snsapi_userinfo （弹出授权页面，可通过 openid 拿到昵称、性别、所在地。并且， 即使在未关注的情况下，只要用户授权，也能获取其信息 ）
   * @param state 重定向后会带上 state 参数，开发者可以填写a-zA-Z0-9的参数值，最多128字节
   * @returns
   */
  getWeiXinAuth2Url(
    appid: string,
    redirectUri: string,
    scope: 'snsapi_base' | 'snsapi_userinfo' = 'snsapi_base',
    state = '',
  ) {
    return `https://open.weixin.qq.com/connect/oauth2/authorize\
?appid=${appid}\
&redirect_uri=${encodeURIComponent(redirectUri)}\
&response_type=code\
&scope=${scope}\
&state=${state}\
#wechat_redirect`;
  }
}

export const urlTool = new UrlTool();

export default urlTool;
