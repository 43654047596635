import axios from 'axios';

import type {
  AxiosInstance,
  Method,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import { logger } from './logger';
import { beacon } from './Beacon';

export { AxiosInstance, Method, AxiosRequestConfig, AxiosResponse };

export class Request {
  public readonly instance: AxiosInstance;

  // 可配置属性 https://axios-http.com/zh/docs/req_config
  private readonly config: AxiosRequestConfig = {
    // `baseURL` 将自动加在 `url` 前面，除非 `url` 是一个绝对 URL。
    // 它可以通过设置一个 `baseURL` 便于为 axios 实例的方法传递相对 URL
    baseURL: '/',

    // `timeout` 指定请求超时的毫秒数。
    // 如果请求时间超过 `timeout` 的值，则请求会被中断
    timeout: 30000, // 默认值是 `0` (永不超时)

    // `withCredentials` 表示跨域请求时是否需要使用凭证
    withCredentials: false, // default false

    // 自定义请求头
    headers: {
      'Content-Type': 'application/json',
    },

    // `responseType` 表示浏览器将要响应的数据类型
    // 选项包括: 'arraybuffer', 'document', 'json', 'text', 'stream'
    // 浏览器专属：'blob'
    responseType: 'json', // 默认值
  };

  public constructor(config?: AxiosRequestConfig) {
    this.config = { ...this.config, ...config };
    this.instance = axios.create(config);
  }

  public preFetch(config: AxiosRequestConfig): AxiosRequestConfig {
    return config;
  }

  public async fetch<Res>(
    url: string,
    method: Method,
    data?: any,
  ): Promise<Res> {
    const config = this.preFetch({
      ...this.config,
      url,
      method,
      data,
    });
    if (method === 'GET') {
      config.params = data;
    }
    try {
      const res = await this.instance.request<any, AxiosResponse<Res>>(config);
      // logger.log('http success:', config.url, {
      //   req: config,
      //   res,
      // });

      const httpStatusCode = res.status;
      if (httpStatusCode !== 200) {
        logger.error(`http statusCode is ${httpStatusCode} 不是期望的 200`);
        beacon.send('服务异常 http status 不是 200', {
          url,
          method,
          data,
          res,
        });
        throw new Error('服务异常');
      }

      // 业务返回数据
      return res.data;
    } catch (err) {
      const info = {
        req: config,
        err,
      };
      logger.error('fetch fail:', config.url, info);
      beacon.send('请求异常', info);
      throw err;
    }
  }
}
