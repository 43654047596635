import React, { useEffect, Suspense, useState } from 'react';
import classNames from 'classnames';
import { Switch, Route } from 'react-router-dom';
import { Empty, Skeleton } from './components';
import { IUser } from './types';
import { context } from './context';
import { apiPatient } from './api';
import { routeConfig, useCurrentRouterItem } from '@/route';
import {
  beacon,
  configPageFitView,
  env,
  getPreFn,
  logger,
  urlTool,
  usePageTitle,
} from '@/utils';

import './common/css/common.scss';
import './App.scss';

configPageFitView.init();

const pre = getPreFn('app');

window.console.log('env', env);

const App: React.FC = () => {
  const [initializing, setInitializing] = useState(true);
  const [user, setUser] = useState<IUser | undefined>(() => context.getUser());

  const currentRoute = useCurrentRouterItem();

  // 统一设置页面标题
  usePageTitle(currentRoute?.title);

  useEffect(() => {
    const init = async () => {
      try {
        const { code, _mp_source: source } = urlTool.getUrlParams();
        logger.log('init user login', { user, code, source });
        // 没登录过，尝试登录（微信登录 code，只能使用一次，所以保存到 session）
        if (currentRoute?.needAuth && !user && code) {
          const res = await apiPatient.login({ code, source });
          logger.log('api.login res', res);
          context.updateAndStoreUser(res);
          beacon.updateConfig({ uid: res.userId });
          setUser(res);
        }
      } finally {
        setInitializing(false);
      }
    };
    init();
  }, [currentRoute, user]);

  let content = null;
  if (initializing) {
    content = <Skeleton />;
  } else if (currentRoute?.needAuth && !user) {
    content = <Empty tips="无权限" isFullPage />;
  } else {
    content = (
      <Suspense fallback={<Skeleton />}>
        <Switch>
          {routeConfig.map(route => (
            <Route
              key={route.path}
              exact={route.exact}
              path={route.path}
              component={route.component}
            />
          ))}
          <Route path="*">
            <Empty tips="404 页面走丢了" isFullPage />
          </Route>
        </Switch>
      </Suspense>
    );
  }

  logger.log('App render', { initializing, currentRoute, user });
  const clz = classNames(pre(), {
    [pre('initializing')]: initializing,
  });
  return <div className={clz}>{content}</div>;
};

export default App;
