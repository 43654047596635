import React from 'react';
import { Skeleton as AntSkeleton } from 'antd-mobile';
import classNames from 'classnames';
import { getPreFn } from '@/utils';
import { ICompProps } from '@/types';

import './index.scss';

interface IProps extends ICompProps {
  animated?: boolean;
  lineCount?: number;
  full?: boolean;
}

const pre = getPreFn('comp-skeleton');

export const Skeleton = (props: IProps) => {
  const { animated = true, lineCount = 5, full = false, className } = props;
  return (
    <div
      className={classNames(
        pre(),
        {
          [pre('full')]: full,
        },
        className,
      )}
    >
      <AntSkeleton.Title animated={animated} />
      <AntSkeleton.Paragraph lineCount={lineCount} animated={animated} />
    </div>
  );
};
