import React from 'react';
import { Toast } from 'antd-mobile';
import type { ToastHandler } from 'antd-mobile/es/components/toast';
import crypto from 'crypto-js';
import { context } from '../context';
import logger from '../pages/patient/logger';
import { IPatientInfo, IUser } from '@/types';
import {
  Request,
  Method,
  AxiosRequestConfig,
  env,
  urlTool,
  beacon,
} from '@/utils';
import { EMPPath } from '@/route';

export interface IReqOption {
  isShowLoading?: boolean;
  loadingMsg?: React.ReactNode;
  isShowErrMsg?: boolean;
  successCode?: number;
}

interface IResponseData {
  [k: string]: any;
}

interface IResponse {
  code: number;
  message: string;
  data: IResponseData;
}

enum EResCode {
  successCode = 0,
  notLogin = 401,
}

function getURL(url: string): string {
  // 区分环境
  let baseURL = 'https://applet.arioncc.cn/'; // 线上环境
  if (env.isDev) {
    const { mock } = urlTool.getUrlParams();
    // baseURL = 'https://applet.arioncc.cn/'; // 线上环境
    // baseURL = `http://10.72.2.191:9789/`; // 测试环境

    // 本机地址时使用 mock 数据
    baseURL = `http://localhost:8080/`;
    // baseURL = 'http://192.168.1.6:8080/';
    // baseURL = `http://10.79.241.1:8080/`;
    // baseURL = `http://10.72.45.193:9789/`;
    if (mock === '1') {
      baseURL = `http://localhost:8080/`;
      baseURL = `http://10.72.44.201:8080/`;
    }
  } else if (env.isTest) {
    baseURL = `http://10.72.2.191:9789/`; // 测试环境
  }
  return url.startsWith('http') ? url : baseURL + url.replace(/^\/+/, '');
}

const salt = 'pbczw4v1nzfdv6iry6yln3vj7nkmucbq';

function signUrl(url: string, method?: Method | string, data: any = {}) {
  // const paramValues = Object.values({
  //   ...urlTool.getUrlParams(url),
  //   ...data,
  // }).filter(v => ['number', 'boolean', 'string'].includes(typeof v));
  let paramValues = Object.values({
    ...urlTool.getUrlParams(url),
  });
  if (method === 'GET') {
    paramValues = paramValues.concat(Object.values(data));
  }
  const timestamp = Date.now();
  const nonce = Math.floor(Math.random() * 100000000);
  const strArr = [salt, timestamp, nonce, ...paramValues].sort();
  const str = strArr.join('');
  const signature = crypto.MD5(str).toString();
  logger.log('signUrl', {
    url,
    data,
    paramValues,
    strArr,
    str,
    signature,
  });
  return urlTool.addUrlParams(url, {
    timestamp,
    nonce,
    signature,
  });
}

export class Patient extends Request {
  public preFetch(config: AxiosRequestConfig): AxiosRequestConfig {
    const { url, method, data } = config;

    const user = context.getUser();
    config.url = getURL(signUrl(url!, method, data));
    config.headers = {
      ...config.headers,
      token: user?.token || '',
      // token: storage.ck.get(cookieSessionKey) || '',
    };
    return config;
  }

  async send(
    url: string,
    method: Method,
    data?: any,
    option?: IReqOption,
  ): Promise<IResponseData> {
    let toastHandler: ToastHandler | undefined;
    let res: IResponse;
    let errMsg: string;
    const {
      isShowLoading = true,
      loadingMsg = '加载中...',
      isShowErrMsg = true,
      successCode = EResCode.successCode,
    } = option || {};
    try {
      if (isShowLoading) {
        toastHandler = Toast.show({
          icon: 'loading',
          content: loadingMsg,
          duration: 0,
          maskClickable: false,
        });
      }
      res = await this.fetch<IResponse>(url, method, data);
    } catch (error: any) {
      errMsg = error.message;
      if (errMsg !== '服务异常') {
        errMsg = `网络异常: ${error.message}`;
      }
      logger.error('fetch error: ', errMsg);
      if (isShowErrMsg) {
        Toast.show({ icon: 'fail', content: errMsg, duration: 3000 });
      }
      throw error;
    }
    switch (res.code) {
      case successCode:
        break;
      case EResCode.notLogin:
        // Toast.show({ icon: 'fail', content: res.message, duration: 3000 });
        wx.miniProgram.redirectTo({
          // 重新进小程序 webview 获取 code 重新验证
          url: urlTool.addUrlParams(EMPPath.webview, {
            url: window.location.href,
          }),
        });
        break;
      default:
        errMsg = `数据异常: ${res.message}`;
        logger.error(`fetch error code[${res.code}]: `, errMsg);
        beacon.send('数据异常', {
          url,
          method,
          data,
          res,
        });
        if (isShowErrMsg) {
          Toast.show({ icon: 'fail', content: errMsg, duration: 3000 });
        }
        throw new Error(errMsg);
    }
    toastHandler?.close();
    return res.data;
  }

  get(url: string, data?: any, option?: IReqOption) {
    return this.send(url, 'GET', data, option);
  }

  post(url: string, data?: any, option?: IReqOption) {
    return this.send(url, 'POST', data, option);
  }

  public postWithQuery(
    url: string,
    query?: any,
    data?: any,
    option?: IReqOption,
  ) {
    return this.send(urlTool.addUrlParams(url, query), 'POST', data, option);
  }

  /**
   * 小程序登录
   * @param params
   * @returns
   */
  async login(params: { code: string; source?: string }): Promise<IUser> {
    const resData = (await this.post('/api/wechat/app/login', params)) as IUser;
    return resData;
  }

  /**
   * 获取小程序用户手机号
   * @param params
   * @returns
   */
  async getPhone(params: {
    iv: string;
    encryptedData: string;
    code: string;
  }): Promise<string> {
    const resData = await this.post('/api/wechat/app/getPhone', params);
    return resData.phone as string;
  }

  /**
   * 获取公众号授权 url
   * @param code
   * @param state
   * @returns
   */
  async getOfficialAuthRedirectUrl(url: string): Promise<string> {
    const resData = await this.get('/api/wechat/official/redirect', {
      url,
    });
    return resData as any as string;
  }

  /**
   * 患者列表
   * @returns
   */
  async getPatientList(): Promise<IPatientInfo[]> {
    const resData = await this.get('/api/patient/list');
    return resData.list as IPatientInfo[];
  }

  /**
   * 患者详情
   * @param patientId
   * @returns
   */
  async getPatientInfo(
    patientId: string,
    option?: IReqOption,
  ): Promise<IPatientInfo> {
    const resData = await this.get('/api/patient/info', { patientId }, option);
    return resData as IPatientInfo;
  }

  /**
   * 检测患者绑定状态
   * @param patientId
   * @returns
   */
  async verifyBindPatient(patientId: string): Promise<{
    status: boolean; // 为 true 已绑定
    phone: string; // status 为 false 没绑定时，返回手机号用于发验证码展示用
  }> {
    const resData = await this.get('/api/patient/verifyBind', { patientId });
    return resData as {
      status: boolean;
      phone: string;
    };
  }

  /**
   * 给患者发送短信验证码
   * @param patientId
   * @returns
   */
  async sendSMSToPatient(
    patientId: string,
  ): Promise<{ status: boolean; freqCheck: boolean }> {
    const resData = await this.postWithQuery('/api/patient/sendSMS', {
      patientId,
    });
    return resData as { status: boolean; freqCheck: boolean };
  }

  /**
   * 给当前小程序用户绑定患者
   * @param patientId
   * @param verificationCode
   * @returns
   */
  async bindPatient(
    patientId: string,
    verificationCode: string,
  ): Promise<boolean> {
    const resData = await this.post('/api/patient/bind', {
      patientId,
      verificationCode,
    });
    return resData.status as boolean;
  }

  /**
   * 获取 h5 打开小程序的 URL Scheme
   * https://developers.weixin.qq.com/miniprogram/dev/framework/open-ability/url-scheme.html
   * https://developers.weixin.qq.com/miniprogram/dev/OpenApiDoc/qrcode-link/url-scheme/generateScheme.html
   * @param params
   * @returns
   */
  async generateScheme(params: {
    is_expire: boolean;
    expire_time: number; // 单位秒，最长有效期为30天。
    /** 跳转到的目标小程序信息 */
    jump_wxa: {
      /** 通过 scheme 码进入的小程序页面路径，必须是已经发布的小程序存在的页面，不可携带 query。path 为空时会跳转小程序主页。 */
      path: string;
      /** 通过 scheme 码进入小程序时的 query，最大1024个字符，只支持数字，大小写英文以及部分特殊字符：`!#$&'()*+,/:;=?@-._~%`` */
      query: string;
      /** 默认值"release"。要打开的小程序版本。正式版为"release"，体验版为"trial"，开发版为"develop"，仅在微信外打开时生效。 */
      env_version: string;
    };
  }): Promise<string> {
    const resData = await this.post('/api/wechat/generateScheme', params);
    return resData as any as string;
  }

  /**
   * 获取动态表单配置
   * @param surveyId
   * @returns
   */
  async getSurveyConfig(surveyId: string): Promise<any> {
    const resData = await this.get('/api/survey/config', { surveyId });
    return resData;
  }

  /**
   * 获取动态表单配置
   * @param surveyId
   * @returns
   */
  async getSurveyModelConfig(surveyId: string): Promise<{
    id: string;
    createTime: string; // ms
    model: string; // json string
    title: string;
    updateTime: string; // ms
  }> {
    const resData = await this.get('/api/survey/model', { surveyId });
    return resData as {
      id: string;
      createTime: string; // ms
      model: string; // json string
      title: string;
      updateTime: string; // ms
    };
  }

  /**
   * 提交动态表单
   * @param jsonStr
   * @returns
   */
  async submitSurvey(surveyId: string, jsonStr: string): Promise<any> {
    const resData = await this.post('/api/survey/submit', {
      surveyId,
      content: jsonStr,
    });
    return resData;
  }
}
