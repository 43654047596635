import classNames from 'classnames';
import { ISvgProps } from '@/types';

import './index.scss';

export const SvgClose = (props: ISvgProps) => {
  const {
    width = '1em',
    height = '1em',
    className,
    style,
    onClick,
    fill = 'currentColor',
  } = props;
  return (
    <svg
      width={width}
      height={height}
      className={classNames('comp-icon', className)}
      style={style}
      onClick={onClick}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.0303 5.96967C17.7557 5.69508 17.3212 5.67792 17.0266 5.91818L16.9697 5.96967L12.0002 10.9392L7.03066 5.96969L6.97369 5.91821C6.67911 5.67794 6.24458 5.6951 5.97 5.96969C5.6771 6.26258 5.6771 6.73746 5.97 7.03035L10.9395 11.9998L5.96967 16.9697C5.67678 17.2626 5.67678 17.7374 5.96967 18.0303C6.24426 18.3049 6.67879 18.3221 6.97337 18.0818L7.03033 18.0303L12 13.0607L16.9697 18.0303L17.0266 18.0818C17.3212 18.3221 17.7557 18.3049 18.0303 18.0303C18.3232 17.7374 18.3232 17.2626 18.0303 16.9697L13.0607 12L18.0303 7.03033C18.3232 6.73744 18.3232 6.26256 18.0303 5.96967Z"
        fill={fill}
      />
    </svg>
  );
};
