import React, { useEffect, useState } from 'react';
import clz from 'classnames';
import { getPreFn } from '@/utils';
import feedbackIcon from './feedback-icon.png';

import './index.scss';

const pre = getPreFn('comp-affix');

export interface IAffixProps {
  /** 图片地址 */
  iconUrl?: string;
  /** 显示/隐藏的内容 */
  content?: React.ReactNode;
  /** 点击后要跳转的网页地址 */
  jumpUrl?: string;
  /** 点击回调，传入当前显示/隐藏状态，默认行为：内容显示时，点击后跳转 jumpUrl */
  onClick?: (isHide: boolean) => void;
}

export function Affix(props: IAffixProps) {
  const { iconUrl = feedbackIcon, content = <div>反馈</div>, onClick } = props;
  const [isHide, setIsHide] = useState(false);

  useEffect(() => {
    const onTouchmove = () => {
      setIsHide(true);
    };
    window.document.addEventListener('touchmove', onTouchmove);
    return () => {
      window.document.removeEventListener('touchmove', onTouchmove);
    };
  }, []);

  const onClickAffix = () => {
    setIsHide(!isHide);
    onClick?.(isHide);
  };

  return (
    <div
      className={clz(pre(), { [pre('hide')]: isHide })}
      onClick={onClickAffix}
    >
      <img className={pre('img')} src={iconUrl} alt="" />
      <div className={pre('content')}>{content}</div>
    </div>
  );
}
