import classNames from 'classnames';
import { ISvgProps } from '@/types';

import './index.scss';

export const SvgDetail = (props: ISvgProps) => {
  const {
    width = '1em',
    height = '1em',
    className,
    style,
    onClick,
    fill = 'currentColor',
  } = props;
  return (
    <svg
      width={width}
      height={height}
      className={classNames('comp-icon', className)}
      style={style}
      onClick={onClick}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.10352 6.14657C7.29878 6.34183 7.29878 6.65841 7.10352 6.85368L5.60352 8.35368C5.40826 8.54894 5.09168 8.54894 4.89642 8.35368L4.14642 7.60368C3.95115 7.40841 3.95115 7.09183 4.14642 6.89657C4.34168 6.70131 4.65826 6.70131 4.85352 6.89657L5.24997 7.29302L6.39642 6.14657C6.59168 5.95131 6.90826 5.95131 7.10352 6.14657Z"
        fill={fill}
      />
      <path
        d="M7.10352 10.1036C7.29878 9.90835 7.29878 9.59177 7.10352 9.39651C6.90826 9.20125 6.59168 9.20125 6.39642 9.39651L5.24997 10.543L4.85352 10.1465C4.65826 9.95125 4.34168 9.95125 4.14642 10.1465C3.95115 10.3418 3.95115 10.6584 4.14642 10.8536L4.89642 11.6036C5.09168 11.7989 5.40826 11.7989 5.60352 11.6036L7.10352 10.1036Z"
        fill={fill}
      />
      <path
        d="M14.25 10.5001C14.25 10.7762 14.0261 11.0001 13.75 11.0001L8.75 11.0001C8.47386 11.0001 8.25 10.7762 8.25 10.5001C8.25 10.2239 8.47386 10.0001 8.75 10.0001H13.75C14.0261 10.0001 14.25 10.2239 14.25 10.5001Z"
        fill={fill}
      />
      <path
        d="M13.75 8.00006C14.0261 8.00006 14.25 7.7762 14.25 7.50006C14.25 7.22392 14.0261 7.00006 13.75 7.00006L8.75 7.00006C8.47386 7.00006 8.25 7.22392 8.25 7.50006C8.25 7.7762 8.47386 8.00006 8.75 8.00006L13.75 8.00006Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.75 1.75C3.09315 1.75 1.75 3.09315 1.75 4.75V13.25C1.75 14.9069 3.09315 16.25 4.75 16.25H13.25C14.9069 16.25 16.25 14.9069 16.25 13.25V4.75C16.25 3.09315 14.9069 1.75 13.25 1.75H4.75ZM13.25 2.75H4.75C3.64543 2.75 2.75 3.64543 2.75 4.75V13.25C2.75 14.3546 3.64543 15.25 4.75 15.25H13.25C14.3546 15.25 15.25 14.3546 15.25 13.25V4.75C15.25 3.64543 14.3546 2.75 13.25 2.75Z"
        fill={fill}
      />
    </svg>
  );
};
