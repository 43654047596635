/*!
 * JavaScript Storage with localStorage, sessionStorage and Cookies
 */
import Cookies from 'js-cookie';

const { localStorage: ls, sessionStorage: ss } = window;

// https://www.npmjs.com/package/js-cookie
export class Storage {
  ck: Cookies.CookiesStatic = Cookies;

  get(key: string): any {
    let value = ls.getItem(key);
    if (value) {
      try {
        value = JSON.parse(value);
      } catch (e) {}
    }
    return value;
  }

  set(key: string, value: any) {
    ls.setItem(key, JSON.stringify(value));
  }

  remove(key: string) {
    ls.removeItem(key);
  }

  clear() {
    ls.clear();
  }

  getSession(key: string): any {
    let value = ss.getItem(key);
    if (value) {
      try {
        value = JSON.parse(value);
      } catch (e) {}
    }
    return value;
  }

  setSession(key: string, value: any) {
    ss.setItem(key, JSON.stringify(value));
  }

  removeSession(key: string) {
    ss.removeItem(key);
  }

  clearSession() {
    ss.clear();
  }
}

export const storage = new Storage();

export default storage;
