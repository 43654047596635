/**
 * 简易埋点上报工具抽象类和一些实用小方法
 */

import { v4 as uuidv4 } from 'uuid';

/**
 * obj 转 json 字符串，跳过循环引用，避免抛出异常，异常时返回 String(obj)
 * @param obj
 * @returns json string
 */
export function toJson(obj: any) {
  let str = '';
  try {
    const cache: any[] = [];
    str = JSON.stringify(obj, (key, value) => {
      if (typeof value === 'object' && value !== null) {
        if (cache.includes(value)) {
          // 循环引用，移除
          return undefined;
        }
        // 收集所有的值
        cache.push(value);
      }
      return value;
    });
  } catch (e) {
    str = String(obj);
  }
  return str;
}

/**
 * 生产 uuid
 * @returns
 */
export function genUuid() {
  return uuidv4();
}

/**
 * fn 是函数则取值是执行结果，否则取值是 fn 自己。 取值是 undefined 返回 defaultValue
 * @param fn
 * @param defaultValue
 * @returns
 */
export function getFnValue<T>(fn?: T | (() => T), defaultValue?: T) {
  let value: T | undefined;
  if (typeof fn === 'function') {
    try {
      value = (fn as () => T)();
    } catch (error) {}
  } else {
    value = fn;
  }
  return value === undefined ? defaultValue : value;
}

export enum EClient {
  web = '浏览器端',
  mp = '微信小程序端',
  // app = '安卓 app 端',
}

/**
 * 全局上报的字段
 */
export interface IConfig {
  /** app id 项目的全局唯一标识，用于区分不同项目的数据等 */
  aid: string;
  /** 客户端环境 */
  client: EClient;
  /** 运行环境，用于对数据进行环境分组，避免线上环境和线下环境的数据相互污染 */
  env: string;
  /** user id 当前访问用户的标识，用于计算UV、影响用户数等 */
  uid?: string;
  /** device id 标识一个设备，默认自动生成，取值为一个uuid，同一设备不变 */
  did?: string | (() => string);
  /** session id 标识一次会话，默认自动生成，取值为一个uuid，进入页面开始到关闭页面为止的整个阶段不变 */
  sid?: string | (() => string);
  /** page id 标识一个具体页面，用于按页面查看数据等，默认自动生成，取值为当前页面全路径 */
  pid?: string | (() => string);
}

export interface IRequestOptions {
  url: string;
  method: 'GET' | 'POST';
  data?: any;
}

// 局部全局变量，默认不重新加载就不变
const uuid = genUuid();

export abstract class Beacon {
  config: IConfig;

  constructor(config: IConfig) {
    this.config = config;
  }

  /**
   * 合并更新全局上报的字段
   * @param config
   */
  updateConfig(config: Partial<IConfig>) {
    this.config = { ...this.config, ...config };
  }

  getUrl() {
    return 'https://open.feishu.cn/open-apis/bot/v2/hook/bb06c569-8a24-4395-9974-32e94cd93be4';
  }

  getExtra() {
    return '';
  }

  getDID() {
    return String(getFnValue(this.config.did, uuid));
  }

  getSID() {
    return String(getFnValue(this.config.sid, uuid));
  }

  getPID() {
    return String(getFnValue(this.config.pid));
  }

  /**
   * 上报发送埋点
   * @param reportName 上报名称
   * @param customParams 其它自定义埋点信息
   */
  send(reportName: string, customParams?: any) {
    let options: any = {};
    // 捕获异常，避免影响业务
    try {
      if (customParams === null || typeof customParams !== 'object') {
        options.name = customParams;
      } else {
        options = { ...customParams };
        options.errName = String(customParams.name);
        options.errMessage = String(customParams.message);
        options.errCause = String(toJson(customParams.cause));
        options.errStack = String(customParams.stack);
      }

      const { config } = this;
      config.did = this.getDID();
      config.sid = this.getSID();
      config.pid = this.getPID();

      const now = new Date();
      const info = {
        ...config,
        options,
        reportName,
        _t: `${now.getFullYear()}-${
          now.getMonth() + 1
        }-${now.getDate()} ${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}.${now.getMilliseconds()}`,
      };

      // 拼装飞书机器人消息，https://open.feishu.cn/document/ukTMukTMukTM/ucTM5YjL3ETO24yNxkjN?lang=zh-CN
      const data = {
        msg_type: 'post',
        content: {
          post: {
            zh_cn: {
              title: `❌ [${info.client}] ${info.aid} 异常：${reportName}`,
              content: [
                [
                  {
                    tag: 'text',
                    text: 'info',
                  },
                ],
                [
                  {
                    tag: 'text',
                    text: toJson(info),
                  },
                ],
                [
                  {
                    tag: 'text',
                    text: `--- --- ---`,
                  },
                ],
                [
                  {
                    tag: 'text',
                    text: 'extra',
                  },
                ],
                [
                  {
                    tag: 'text',
                    text: this.getExtra(),
                  },
                ],
              ],
            },
          },
        },
      };

      this.request({
        url: this.getUrl(),
        method: 'POST',
        data,
      });
    } catch (error) {}
  }

  abstract request(requestOptions: IRequestOptions): Promise<void>;
}
