/**
 * 设置页面标题
 * @param title
 */
export function setPageTitle(title?: string) {
  if (title !== undefined) {
    window.document.title = title;
  }
}

/**
 * 返回指定前缀 prefix 的新函数 fn
 * @param prefix
 * @returns eg: fn(suffix) => prefix-suffix | fn(=) => prefix
 */
export function getPreFn(prefix: string) {
  return (suffix?: string) => (suffix ? `${prefix}-${suffix}` : prefix);
}

export function padStart(
  obj: any,
  maxLength: number,
  fillString?: string | undefined,
): string {
  return String(obj).padStart(maxLength, fillString);
}

export function padEnd(
  obj: any,
  maxLength: number,
  fillString?: string | undefined,
): string {
  return String(obj).padEnd(maxLength, fillString);
}

export function isEmpty(obj: any) {
  return obj === null || obj === undefined || obj === '';
}

export function toStr(obj: any, defaultStr = '--') {
  return isEmpty(obj) ? defaultStr : String(obj);
}

/**
 * 返回日期对象
 * @param time
 * @returns Date 直接返回；number 表示的毫秒值对应的 Date；
 * string：用-/:.或空格分隔的字符串对应的日期（分隔出现顺序：年、月、日、时、分、秒、毫秒），
 * 如 YYYY-MM-DD HH:MM:SS or YYYY/MM-DD HH:MM:SS 对应的 Date；
 * 其它默认当前日期。
 */
export function toDate(time: Date | number | string) {
  if (time instanceof Date) {
    return time;
  }

  // 包括处理字符串表示的负数时间
  if (typeof time === 'number' || !Number.isNaN(Number(time))) {
    return new Date(Number(time));
  }

  if (typeof time === 'string') {
    const args: number[] = time
      .replace(/([-:/.])|(\s+)/gi, ',')
      .split(',')
      .map(i => Number(i));
    if (args[1]) {
      // 月份从 0 开始
      args[1] -= 1;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    return new Date(...args);
  }

  // 默认当前
  return new Date();
}

/**
 * date to string
 *
 * @param time 日期对象 | 毫秒的字符串 | 毫秒的数字
 * @param mode 数字 1 | 2 | 3
 * 1 YYYY-MM-DD
 * 2 YYYY-MM-DD HH:MM
 * 3 YYYY-MM-DD HH:MM:SS
 * @param type 指定年月日之间的分隔符，默认'-'
 * @param defaultTimeStr 非法时间时的展示字符串
 * @returns {string}
 */
export function formatDate(
  time: Date | number | string,
  mode = 1,
  type = '-',
): string {
  const date = toDate(time);

  let dateItems = [
    date.getFullYear(),
    type,
    padStart(date.getMonth() + 1, 2, '0'),
    type,
    padStart(date.getDate(), 2, '0'),
  ];
  switch (mode) {
    case 1:
      break;
    case 2:
      dateItems = [
        ...dateItems,
        ' ',
        padStart(date.getHours(), 2, '0'),
        ':',
        padStart(date.getMinutes(), 2, '0'),
      ];
      break;
    case 3:
      dateItems = [
        ...dateItems,
        ' ',
        padStart(date.getHours(), 2, '0'),
        ':',
        padStart(date.getMinutes(), 2, '0'),
        ':',
        padStart(date.getSeconds(), 2, '0'),
      ];
      break;
    default:
      throw new Error(`指定时间 mode 参数出错，期望1 | 2 | 3，但输入了${mode}`);
  }
  return dateItems.join('');
}

export const configPageFitView: {
  designedWidth: number;
  maxWidth: number;
  minWidth: number;
  _bind: boolean;
  _fitView: () => void;
  init: (
    designedWidth?: number,
    maxFitWidth?: number,
    minFitWidth?: number,
  ) => void;
} = {
  designedWidth: 375,
  maxWidth: 750,
  minWidth: 0,
  _bind: false,

  _fitView() {
    /**
     * 把设计图中的px转为对应数值缩小100倍的rem
     */
    const { body, documentElement: docEl } = window.document;
    let { clientWidth } = docEl;
    if (!clientWidth) {
      return;
    }
    if (clientWidth > this.maxWidth) {
      clientWidth = this.maxWidth;
    }
    if (clientWidth < this.minWidth) {
      clientWidth = this.minWidth;
    }
    const rootSize = 100 * (clientWidth / this.designedWidth);
    docEl.style.fontSize = `${rootSize}px`;
    body.style.width = `${clientWidth}px`;
    body.style.margin = 'auto';
  },

  /**
   * 设置根字体大小，rem 适配页面宽度
   * @param designedWidth 设计稿宽度
   * @param maxFitWidth 最大适配宽度
   * @param minFitWidth 最小适配宽度
   */
  init(designedWidth = 375, maxFitWidth?: number, minFitWidth?: number) {
    this.maxWidth = parseInt(String(maxFitWidth), 10) || 2 * designedWidth;
    this.minWidth = parseInt(String(minFitWidth), 10) || 0.5 * designedWidth;
    this.designedWidth =
      parseInt(String(designedWidth), 10) || this.designedWidth;
    this._fitView();

    if (!this._bind) {
      const resize =
        'orientationchange' in window ? 'orientationchange' : 'resize';
      window.addEventListener(resize, () => this._fitView(), false);
    }
  },
};

/**
 * 返回数字（不是数字尝试 parseFloat 处理）指定小数位的字符串，其他情况原样返回
 * @param s
 * @param fractionDigits 保留小数位
 * @returns
 */
export function toFixed(s: any, fractionDigits?: number) {
  if (isEmpty(s)) {
    return s;
  }
  let num = s as number;
  if (typeof s === 'string') {
    num = parseFloat(s);
    if (Number.isNaN(num)) {
      return s;
    }
  }
  return num.toFixed(fractionDigits);
}
