import { env } from './constant';

export enum ELoggerLevel {
  debug = 0,
  log,
  info,
  warn,
  error,
  none,
}

const colorTable = {
  [ELoggerLevel.debug]: '#66c40e',
  [ELoggerLevel.log]: '#0ec4be',
  [ELoggerLevel.info]: '#0e5dc4',
  [ELoggerLevel.warn]: '#840ec4',
  [ELoggerLevel.error]: '#c40e0e',
};

type LoggerMethodName = keyof typeof ELoggerLevel;

export class Logger {
  public pre: string;

  public level: ELoggerLevel;

  constructor(pre = '[my-log]', level = ELoggerLevel.error) {
    this.pre = pre;
    this.level = level;
  }

  private myLog(methodName: LoggerMethodName, ...args: any[]) {
    if (methodName === 'none') {
      return;
    }

    if (this.level <= ELoggerLevel[methodName]) {
      const date = new Date();
      const preContent = `[${methodName} ${date.getMinutes()}:${date.getSeconds()}.${date.getMilliseconds()}]`;
      // eslint-disable-next-line no-console
      if (typeof console[methodName] !== 'function') {
        // 避免不是方法调用时导致异常，影响正常业务。
        return;
      }
      if (env.isWX) {
        // eslint-disable-next-line no-console
        console[methodName](`${this.pre + preContent}:`, ...args);
      } else {
        // eslint-disable-next-line no-console
        console[methodName](
          `%c${this.pre + preContent}:`,
          `color: ${colorTable[ELoggerLevel[methodName]]}; font-weight: 700`,
          ...args,
        );
      }
    }
  }

  public debug(...args: any[]) {
    this.myLog('debug', ...args);
  }

  public log(...args: any[]) {
    this.myLog('log', ...args);
  }

  public info(...args: any[]) {
    this.myLog('info', ...args);
  }

  public warn(...args: any[]) {
    this.myLog('warn', ...args);
  }

  public error(...args: any[]) {
    this.myLog('error', ...args);
  }

  // 返回指定参数（默认继承当前实例属性）的新日志实例
  public newInstance(pre?: string, level?: ELoggerLevel) {
    return new Logger(pre || this.pre, level || this.level);
  }
}

// 初始化日志工具，单例模式
export const logger = new Logger(
  '[arion-patient-h5]',
  env.isProd ? ELoggerLevel.error : ELoggerLevel.debug,
);

export default logger;
