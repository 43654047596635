// Jupiter 内置了对环境变量的支持 https://jupiter.goofy.app/docs/apis/runtime/env
// NODE_ENV
// production：执行 jupiter build、jupiter preview 命令时的默认值。
// test：执行 jupiter test 命令时的默认值。
// development：执行 jupiter dev 命令时的默认值，同时也是其他所有情况下的默认值。

const ua = navigator.userAgent;

// 环境判断
let ENV: string = String(process.env.NODE_ENV);
const deployEnv = process.env.REACT_APP_DEPLOY_ENV;
if (deployEnv) {
  ENV = deployEnv;
}
export const enum EEnv {
  development = 'development',
  test = 'test',
  production = 'production',
}
export const env = {
  ENV,
  isDev: ENV === EEnv.development,
  isTest: ENV === EEnv.test,
  isProd: ENV === EEnv.production,
  isMiniProgram: /miniProgram/i.test(ua),
  isWX: /MicroMessenger/i.test(ua),
};

// WeixinJSBridge
const wx = window.wx || {};
if (wx.miniProgram && typeof wx.miniProgram.getEnv === 'function') {
  wx.miniProgram.getEnv(res => {
    env.isMiniProgram = res.miniprogram;
  });
}

/** 门诊患者满意度调查问卷 id */
export const treatSurveyId = '1';
