import React, { useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { env } from '@/utils';

export * from './mp';

export interface IRouteItem {
  title?: string;
  needAuth?: boolean;
  path: string;
  exact?: boolean; // 默认 false
  strict?: boolean; // 默认 false
  component?: React.ComponentType;
  children?: IRouteItem[];
  parent?: IRouteItem;
}

// 页面名称路由 map
export enum EPath {
  patient = '/patient',
  patientList = '/patient/list',
  patientDetail = '/patient/detail',

  wxScan = '/wx-scan',
  jumpMp = '/jump-mp',
  survey = '/survey',

  mpPrivacyPolicy = '/mp-privacy-policy',

  mpUserAgreement = '/mp-user-agreement',
}

// Outer 服务页面名称路由 map
let baseOuter = 'https://survey-form.arioncc.cn';
if (env.isDev) {
  baseOuter = 'http://10.72.2.108:30009';
} else if (env.isTest) {
  baseOuter = 'http://10.72.2.108:30009';
}

export const pathMapOuter = {
  // 查看报告 eg: /check-query-list?patientId=10000000751
  report: `${baseOuter}/check-query-list`,
};

function lazy(path: EPath) {
  // 指定 webpackInclude 按页面级别 bundled
  return React.lazy(
    () =>
      import(
        /* webpackInclude: /\/pages\/([\w-]+)\/index.tsx$/ */
        /* webpackChunkName: "[request]" */
        `../pages${path}`
      ),
  );
}

const wxScanPage = {
  title: '微信扫码',
  needAuth: false,
  path: EPath.wxScan,
  exact: false,
  component: lazy(EPath.wxScan),
};
const jumpMpPage = {
  title: '打开小程序',
  needAuth: false,
  path: EPath.jumpMp,
  exact: false,
  component: lazy(EPath.jumpMp),
};
const surveyPage = {
  title: '问卷反馈',
  needAuth: true,
  path: EPath.survey,
  exact: false,
  component: lazy(EPath.survey),
};
const mpPrivacyPolicyPage = {
  title: '隐私政策',
  needAuth: false,
  path: EPath.mpPrivacyPolicy,
  exact: false,
  component: lazy(EPath.mpPrivacyPolicy),
};
const mpUserAgreementPage = {
  title: '美中爱瑞用户协议',
  needAuth: false,
  path: EPath.mpUserAgreement,
  exact: false,
  component: lazy(EPath.mpUserAgreement),
};
const patientPage = {
  title: '就诊人列表',
  needAuth: true,
  path: EPath.patient,
  exact: false,
  component: lazy(EPath.patient),
  children: [
    {
      title: '就诊人列表',
      needAuth: true,
      path: EPath.patientList,
      exact: true,
      // component, // 由子系统处理，或看情况配置（请使用懒加载，避免增加加载入口文件大小）
    },
    {
      title: '就诊信息',
      needAuth: true,
      path: EPath.patientDetail,
      exact: true,
    },
  ],
};

// 页面路由配置
export const routeConfig: IRouteItem[] = [
  wxScanPage,
  jumpMpPage,
  surveyPage,
  mpPrivacyPolicyPage,
  mpUserAgreementPage,
  patientPage,
];

/**
 * 获取项目路由配置
 * @param routeConfig
 * @param path
 * @returns RouterConfig | null
 */
export function getRouterItem(
  routeConfig: IRouteItem[],
  path: string,
): IRouteItem | null {
  if (!routeConfig || !path) {
    return null;
  }
  let routerItem: IRouteItem | null = null;

  for (let i = 0, len = routeConfig.length; i < len; i++) {
    const item = routeConfig[i];
    const { path: itemPath, exact = false, strict = false, children } = item;

    // 优先匹配子路由
    if (children) {
      routerItem = getRouterItem(children, path);
    } else {
      const match = matchPath(path, {
        path: itemPath,
        exact,
        strict,
      });
      if (match) {
        routerItem = item;
      }
    }
    if (routerItem) {
      break;
    }
  }

  return routerItem;
}

/**
 * 获取当前页面的路由配置
 * @returns
 */
export function useCurrentRouterItem(): IRouteItem | null {
  const location = useLocation();
  return useMemo(
    () => getRouterItem(routeConfig, location.pathname),
    [location],
  );
}

/**
 * 获取当前路由的全部父级路由
 * @param routeItem
 * @returns
 */
export function getParentRoutes(routeItem: IRouteItem | null): IRouteItem[] {
  const parents: IRouteItem[] = [];
  let parent = routeItem?.parent;
  while (parent) {
    parents.push(parent);
    ({ parent } = parent);
  }
  return parents.reverse();
}
