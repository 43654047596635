/**
 * 网页版
 */

import { EEnv, env } from '../constant';
import { Beacon, EClient, IConfig, IRequestOptions, toJson } from './base';

const sidKey = '__beacon_sid';
const didKey = '__beacon_did';
const { location, localStorage, sessionStorage, navigator, console } = window;

/** 要忽略的全局报错信息（不影响功能） */
const ignoreMsg = ['ResizeObserver loop limit exceeded'];

class BrowserBeacon extends Beacon {
  constructor(config: IConfig) {
    super(config);
    const onError = (ev: ErrorEvent): void => {
      const { type, message, filename, lineno, colno, error } = ev;
      // alert(`${message}\n${filename}, ${lineno}:${colno}`);
      // console.log('全局异常', ev);
      if (ignoreMsg.some(msg => message.indexOf(msg) > -1)) {
        return;
      }
      this.send('全局异常', {
        desc: `${type}: ${message}\n${filename}, ${lineno}:${colno}`,
        error,
      });
    };
    window.addEventListener('error', onError);
    console.log('BrowserBeacon init', config);
  }

  async request({ url, method, data }: IRequestOptions): Promise<void> {
    if (this.config.env === EEnv.production) {
      const req = new XMLHttpRequest();
      req.open(method, url);
      req.setRequestHeader('Content-Type', 'application/json');
      req.send(toJson(data));
    } else {
      console.log('Beacon send request', data);
    }
  }

  getExtra() {
    return 'userAgent: ' + navigator.userAgent;
  }

  getDID() {
    let id = localStorage.getItem(didKey);
    if (!id) {
      id = super.getDID();
      localStorage.setItem(didKey, id);
    }
    return id;
  }

  getSID() {
    let id = sessionStorage.getItem(sidKey);
    if (!id) {
      id = super.getSID();
      sessionStorage.setItem(sidKey, id);
    }
    return id;
  }

  getPID() {
    return location.href;
  }
}

/** 简易埋点上报工具，全局单例 beacon */
export const beacon = new BrowserBeacon({
  aid: 'arion-patient-h5 患者移动端',
  env: env.ENV,
  client: EClient.web,
});
