import React from 'react';
import classNames from 'classnames';
import { getPreFn } from '@/utils';
import { ICompProps } from '@/types';

import './index.scss';

interface IProps extends ICompProps {
  isFullPage?: boolean;
  isFullContainer?: boolean;
  tips?: React.ReactNode;
}

const pre = getPreFn('comp-empty');

export const Empty = (props: IProps) => {
  const {
    tips = '暂无内容！',
    isFullPage = false,
    isFullContainer = false,
    className,
  } = props;
  return (
    <div
      className={classNames(
        pre(),
        {
          [pre('full-container')]: isFullContainer,
          [pre('full-page')]: isFullPage,
        },
        className,
      )}
    >
      <div className={pre('empty-icon')} />
      <div className={pre('tips')}>{tips}</div>
    </div>
  );
};
